import { firebaseAction } from 'vuexfire';
import firebaseReference from './FirebaseReference';

/**
 * Firebase -> store bindings
 * it's defined through vuexFire and called by the bindToFirebase action
 */
export default {
  /**
   * Bind the social wall config to state.wallConfigData
   */
  bindWallConfig: firebaseAction(async function callback({ getters, bindFirebaseRef, commit })
  {
    console.log('Binding wall config');
    return bindFirebaseRef('wallConfigData', await firebaseReference.root);
  }),
  /**
   * Bind the event animations to state.animations
   */
  bindAnimations: firebaseAction(async function callback({ getters, bindFirebaseRef })
  {
    console.log('Binding animations');
    const ref = await firebaseReference.ref;
    return bindFirebaseRef('animations', ref.ref('animations').child(getters.eventID));
  }),
  /**
   * Bind the event datas to state.datas
   */
  bindDatas: firebaseAction(async function callback({ getters, bindFirebaseRef })
  {
    console.log('Binding datas');
    const ref = await firebaseReference.ref;
    return bindFirebaseRef('datas', ref.ref('datas').child(getters.eventID));
  }),
};
