import PerfectScrollbar from 'vue2-perfect-scrollbar';
import VModal from 'vue-js-modal';
import Vue from 'vue';
import Vuex from 'vuex';
import VueNotifications from 'vue-notifications';
import VueCookies from 'vue-cookies';
import Tooltip from 'vue-directive-tooltip';
import Noty from 'noty';
import moment from 'moment';
import 'bootstrap/dist/js/bootstrap.bundle';
import App from './App.vue';
import store from './store';
import 'noty/lib/noty.css';
import 'noty/lib/themes/sunset.css';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'flatpickr/dist/flatpickr.css';
import '../public/style/custom.scss';

const Chance = require('chance');

Vue.config.productionTip = false;
Vue.use(PerfectScrollbar);
Vue.use(VModal);
Vue.use(VueCookies);
Vue.use(Tooltip, {
  delay: { show: 500, hide: 0 },
});

moment.locale('fr');
Vue.prototype.$moment = moment;
Vue.prototype.$chance = new Chance();
Vue.prototype.$newIDParams = {
  length: 5,
  symbols: false,
  casing: 'lower',
  numeric: true,
  alpha: true,
};

function toast({
  title,
  message,
  type,
  timeout,
  cb,
}) {
  if (type === VueNotifications.types.warn)
    type = 'warning';
  return new Noty({
    theme: 'sunset',
    text: `<h5 class="text-light">${title}</h5><p>${message}</p>`,
    timeout,
    type,
    layout: 'bottomRight',
  }).show();
}

const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
};

Vue.use(VueNotifications, options);
Vue.use(Vuex);

new Vue({
  store,
  render: (h) => h(App),
}).$mount('#app');
