<template>
  <div >
    <Tabs v-bind:tabs="[`<span class='text-secondary'>M. internes</span>`, `<span class='text-secondary'>M. externes</span>`]">
      <template v-slot:slot-0>
        <div class="row">
          <div class="col pe-0">
            <label class="small" for="padding-top">Haut</label>
            <input v-bind:value="padding.top" v-on:change="$emit('change', { ...value, padding: { ...padding, top: $event.target.value } })" id="padding-top" type="number" class="form-control pe-1" placeholder="">
          </div>
          <div class="col p-0">
            <label class="small" for="padding-bottom">Bas</label>
            <input v-bind:value="padding.down" v-on:change="$emit('change', { ...value, padding: { ...padding, down: $event.target.value } })" id="padding-bottom" type="number" class="form-control pe-1" placeholder="">
          </div>
          <div class="col p-0">
            <label class="small" for="padding-left">Gauche</label>
            <input v-bind:value="padding.left" v-on:change="$emit('change', { ...value, padding: { ...padding, left: $event.target.value }})" id="padding-left" type="number" class="form-control pe-1" placeholder="">
          </div>
          <div class="col p-0">
            <label class="small" for="padding-right">Droite</label>
            <input v-bind:value="padding.right" v-on:change="$emit('change', { ...value, padding: { ...padding, right: $event.target.value } })" id="padding-right" type="number" class="form-control pe-1" placeholder="">
          </div>
        </div>
      </template>
      <template v-slot:slot-1>
        <div class="row">
          <div class="col pe-0">
            <label class="small" for="margin-top">Haut</label>
            <input v-bind:value="margin.top" v-on:change="$emit('change', { ...value, margin: { ...margin, top: $event.target.value } })" id="margin-top" type="number" class="form-control pe-1" placeholder="">
          </div>
          <div class="col p-0">
            <label class="small" for="margin-bottom">Bas</label>
            <input v-bind:value="margin.down" v-on:change="$emit('change', { ...value, margin: { ...margin, down: $event.target.value } })" id="margin-bottom" type="number" class="form-control pe-1" placeholder="">
          </div>
          <div class="col p-0">
            <label class="small" for="margin-left">Gauche</label>
            <input v-bind:value="margin.left" v-on:change="$emit('change', { ...value, margin: { ...margin, left: $event.target.value }})" id="margin-left" type="number" class="form-control pe-1" placeholder="">
          </div>
          <div class="col p-0">
            <label class="small" for="margin-right">Droite</label>
            <input v-bind:value="margin.right" v-on:change="$emit('change', { ...value, margin: { ...margin, right: $event.target.value } })" id="margin-right" type="number" class="form-control pe-1" placeholder="">
          </div>
        </div>
      </template>
    </Tabs>
  </div>
</template>

<script>

import Tabs from './Tabs.vue';

export default {
  name: 'SpacingEditor',
  model: {
    prop: 'value',
    event: 'change',
  },
  components: {
    Tabs,
  },
  computed: {
    margin() {
      return (this.value.margin !== undefined) ? this.value.margin : {};
    },
    padding() {
      return (this.value.padding !== undefined) ? this.value.padding : {};
    },
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: 'Marges',
    },
  },
};
</script>

<style scoped>

</style>
