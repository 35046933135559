const state = {
  tokenID: '',
  loading: true,
  loadingError: false,
  firebaseSpinner: false,
  // WALL JSON DATA
  animations: {},
  datas: {},
  wallConfigData: {},
};

export default state;
