<template>
  <NewConfig v-model="wallType" v-if="!wallType || wallType === ''"/>
  <form v-else @submit.stop.prevent>
    <div class="mb-3">
      <label class="form-label">Type de mur</label>
      <select class="form-select" v-model="wallType">
        <option selected disabled value="">Veuillez choisir un type de mur</option>
        <option value="photowall">Photowall</option>
        <option value="socialwall">Social Wall</option>
        <option value="carousel" disabled class="disabled">Carrousel</option>
        <option value="slideshow" disabled class="disabled">Slide show</option>
      </select>
    </div>
    <div class="mb-3"><hr></div>
    <photowallConfig v-if="wallType === 'photowall'" v-model="photoWallSettings"/>
    <socialWallConfig v-if="wallType === 'socialwall'" v-model="socialWallSettings"/>
    <a></a>
  </form>
</template>

<script>

import { mapGetters } from 'vuex';
import socialWallConfig from './configurations/socialWallConfig.vue';
import PhotowallConfig from './configurations/photowallConfig.vue';
import NewConfig from './NewConfig.vue';
import defaultPhotowallConfig from '../Configuration/DefaultPhotowall.json';

export default {
  name: 'WallConfiguration',
  components: { NewConfig, PhotowallConfig, socialWallConfig },
  computed: {
    ...mapGetters(['wallConfig']),
    wallType: {
      get()
      {
        return this.wallConfig.type || '';
      },
      set(newType)
      {
        switch (newType)
        {
          case 'photowall':
            this.$store.commit('setWallConfig', { config: { photoWall: defaultPhotowallConfig, ...this.wallConfig, type: newType }, dbSync: true });
            break;
          default:
            this.$store.commit('setWallConfig', { config: { ...this.wallConfig, type: newType }, dbSync: true });
            break;
        }
      },
    },
    photoWallSettings: {
      get()
      {
        if (this.wallConfig)
          return this.wallConfig.photoWall || {};
        return {};
      },
      set(newSettings)
      {
        const out = { ...this.wallConfig };
        out.photoWall = { ...this.photoWallSettings, ...newSettings };
        this.$store.commit('setWallConfig', { config: out, dbSync: true });
      },
    },
    socialWallSettings: {
      get()
      {
        if (this.wallConfig)
          return this.wallConfig.socialWall || {};
        return {};
      },
      set(newSettings)
      {
        const out = { ...this.wallConfig };
        out.socialWall = { ...this.socialWallSettings, ...newSettings };
        this.$store.commit('setWallConfig', { config: out, dbSync: true });
      },
    },
  },
};

</script>

<style scoped>

</style>
