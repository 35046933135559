<template>
  <div class="text-light row p-2 d-flex align-items-center topbar bg-secondary position-relative">
    <div class="col-auto">
      <img src="wallIcon.png" style="width: 50px;" alt="project icon">
    </div>
    <div class="col-auto d-flex align-items-center">
      <div class="row">
        <div class="col-12">
          <h5>CAPTAG SOCIAL WALL - <span class="text-primary">{{ wallName }}</span></h5>
        </div>
        <div class="col-12">
          <a target="_blank" class="small text-muted" :href="`https://captag-events-default-rtdb.europe-west1.firebasedatabase.app/animations/${path}`">{{ path }}</a>
        </div>
      </div>
    </div>
    <div class="ms-auto col-auto">
      <button @click="$refs.addFontModal.open()" class="btn btn-info btn-raised text-light" type="button">EDITEUR DE POLICES<i class="ms-2 fas fa-pen"></i></button>
    </div>
    <div class="col-auto">
      <button @click="$refs.customCSSModal.open()" class="btn btn-primary btn-raised text-light" type="button">EDITEUR DE STYLE<i class="ms-2 fab fa-css3-alt"></i></button>
    </div>
    <div class="col-auto">
      <button @click="$refs.deploymentModal.open()" class="btn btn-danger btn-raised text-light" type="button">PUBLIER LE MUR<i class="ms-2 fas fa-save"></i></button>
    </div>
    <DeploymentModal ref="deploymentModal" class="text-black"/>
    <AddFontModal ref="addFontModal" v-model="fontList"/>
    <customCodeEditorModal ref="customCSSModal" title="Éditeur de CSS personnalisé" mode="text/css" v-model="customStyle" id="customCssModal" />
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import DeploymentModal from './Deployment/DeploymentModal.vue';
import AddFontModal from './FontManager/FontManagerModal.vue';
import customCodeEditorModal from './CustomCodeEditorModal.vue';

export default {
  name: 'TopBar',
  components: { DeploymentModal, AddFontModal, customCodeEditorModal },
  computed: {
    ...mapGetters(['wallName', 'path', 'googleFonts', 'customCSS']),
    customStyle: {
      get() {
        return this.customCSS;
      },
      set(value) {
        this.$store.commit('setCustomCSS', { value, dbSync: true });
      },
    },
    fontList: {
      get()
      {
        return this.googleFonts || {};
      },
      set(fontData)
      {
        this.$store.commit('addGoogleFont', { fontObject: fontData, dbSync: true });
      },
    },
  },
};

</script>

<style scoped>

.topbar {
  z-index: 1000;
}

</style>
