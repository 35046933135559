<template>
  <div>
    <label v-if="label" class="small">{{ label }}</label>
    <select class="form-select" v-bind:value="value" v-on:input="$emit('change', $event.target.value)">
      <option class="disabled" value="" selected>Police par défaut</option>
      <optgroup label="Police de base">
        <option v-for="(ft, key) in baseFont" v-bind:key="key" v-bind:value="ft.value" >{{ft.name}}</option>
      </optgroup>
      <optgroup label="Polices particulières">
        <option v-for="(ft, key) in serverFont" v-bind:key="key" v-bind:value="ft.value" >{{ft.name}}</option>
      </optgroup>
      <optgroup v-if="importedFonts && importedFonts !== {}" label="Polices Fonts importées">
        <option v-for="(ft, key) in importedFonts" v-bind:key="key" v-bind:value="`${ft.family}${ft.category ? `, ${ft.category}` : ''}`" >{{ft.family}} (Police importée)</option>
      </optgroup>
    </select>
  </div>
</template>

<script>

import { baseFont, serverFont } from '../../Configuration/defaultFont.json';

export default {
  name: 'FontSelector',
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    importedFonts: {
      type: Object,
      required: false,
      default: () => undefined,
    },
  },
  model: {
    model: 'value',
    event: 'change',
  },
  computed: {
    serverFont()
    {
      return serverFont;
    },
    baseFont()
    {
      return baseFont;
    },
  },
};

</script>

<style scoped>

</style>
