<template>
  <div class="row">
    <div class="col d-flex align-items-center">
      <div class='slidecontainer'>
        <input type="range" v-bind:min="min" v-bind:max='max' class='slider' v-bind:value="value" v-on:input="$emit('change', parseInt($event.target.value, 10))">
      </div>
    </div>
    <div class="col-auto text-center">
      <label>{{ value | formatTime(formater) }}</label>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Slider',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: false,
      default: 1440,
    },
    min: {
      type: Number,
      required: false,
      default: 2,
    },
    formater: {
      type: Function,
      default: (value) => {
        if (value === 1440)
          return '1 jour';
        if (value > 60)
          if (value % 60 !== 0)
            return `${Math.floor(value / 60)}h ${value % 60}m`;
          else
            return `${Math.floor(value / 60)}h`;
        return `${value} min`;
      },
    },
  },
  filters: {
    formatTime(value, formater) {
      return formater(value);
    },
  },
};

</script>

<style scoped>

.slidecontainer {
  width: 100%; /* Width of the outside container */
}

/* The slider itself */
.slider {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 15px; /* Specified height */
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
  border-radius: 10px;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #FAA725; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 10px;
}

.slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #FAA725; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 30px;
  border: 0;
}

</style>
