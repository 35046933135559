<template>
  <div>
    <ul class="nav nav-tabs nav-justified">
      <li v-for="(tab, tabIdx) in tabs" v-bind:id="tabIdx" v-bind:key="tabIdx" class="nav-item">
        <a v-bind:class="(activeTab === tabIdx) ? 'active' : ''" class="nav-link" v-bind:aria-current="`tab-${tabIdx}`" style="cursor: pointer" v-on:click="setActiveTab(tabIdx)" v-html="tab"></a>
      </li>
    </ul>
    <div v-if="animation" class="position-relative" style="height: calc(100% - 90px)">
      <transition name="slide-fade" v-for="(tab, tabIdx) in tabs" v-bind:key="tabIdx">
        <slot v-if="activeTab === tabIdx" v-bind:name="`slot-${tabIdx}`">
        </slot>
      </transition>
    </div>
    <div v-else class="position-relative" style="height: calc(100% - 90px)">
      <template v-for="(tab, tabIdx) in tabs">
        <slot v-if="activeTab === tabIdx" v-bind:name="`slot-${tabIdx}`">
        </slot>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Tabs',
  data() {
    return {
      activeTab: 0,
    };
  },
  mounted() {
    this.$emit('tabChanged', 0);
  },
  methods: {
    setActiveTab(tabIdx)
    {
      this.activeTab = tabIdx;
      this.$emit('tabChanged', tabIdx);
    },
    getActiveTab()
    {
      return this.activeTab;
    },
  },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    animation: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};

</script>

<style>

.slide-fade-enter-active {
  transition: all .2s ease;
}
.slide-fade-enter {
  transform: translateX(-100%);
}

</style>
