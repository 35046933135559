<template>
  <div class="mb-3">
    <div class="row">
      <div class="col-12 text-center my-4">
        <h3>Choisissez votre type de mur</h3>
      </div>
    </div>
    <div class="row" style="height: 250px;">
      <div class="col-6 h-100">
        <button @click="current = 'photowall'" :class="current === 'photowall' ? 'btn-success text-light' : 'btn-outline-secondary'"  class="btn w-100 h-100"><i class="fas fa-photo-video fa-10x mb-4"></i><br><h3>Photowall</h3></button>
      </div>
      <div class="col-6 h-100">
        <button @click="current = 'socialwall'" :class="current === 'socialwall' ? 'btn-success text-light' : 'btn-outline-secondary'" class="btn w-100 h-100"><i class="fas fa-users fa-10x mb-4"></i><br><h3>Social Wall</h3></button>
      </div>
    </div>
    <div class="row mt-5" style="height: 250px;">
      <div class="col-6 h-100">
        <button @click="current = 'carousel'" :class="current === 'carousel' ? 'btn-success text-light' : 'btn-outline-secondary'" class="btn w-100 h-100"><i class="fas fa-horse fa-10x mb-4"></i><br><h3>Carousel</h3></button>
      </div>
      <div class="col-6 h-100">
        <button @click="current = 'slideshow'" :class="current === 'slideshow' ? 'btn-success text-light' : 'btn-outline-secondary'" class="btn w-100 h-100"><i class="fas fa-file-powerpoint fa-10x mb-4"></i><br><h3>Slide show</h3></button>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-auto mx-auto">
        <button @click="submit" :disabled="current && current !== '' ? undefined : true" :class="current && current !== '' ? '' : 'disabled'" class="btn btn-lg btn-success text-light"><h3>Valider</h3></button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NewConfig',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data()
  {
    return {
      current: '',
    };
  },
  methods: {
    submit()
    {
      this.$emit('change', this.current);
    },
  },
};

</script>

<style scoped>

</style>
