<template>
  <modal :name="id" width="90%" height="95%" @scroll.prevent>
    <card title="Editeur de grille" class="h-100">
      <template>
        <div class="row">
          <div class="col-auto">
            <button class="btn btn-info btn-raised disabled text-light" @click="autoDetect()"><i class="fas fa-cogs me-2"></i>Définir les cases automatiquement</button>
          </div>
          <div class="col-auto">
            <button class="btn btn-primary text-light btn-raised" @click="selectAll()"><i class="fas fa-check-square me-2"></i>Sélectionner tout</button>
          </div>
          <div class="col-auto">
            <button class="btn btn-danger text-light btn-raised" @click="deselectAll()"><i class="far fa-square me-2"></i>Déselectionner tout</button>
          </div>
          <div class="col-auto me-auto">
            <button class="btn btn-success btn-raised text-light" @click="save()"><i class="fas fa-check me-2"></i>Sauvegarder</button>
          </div>
          <div class="col-auto">
            <div class="btn-group" role="group">
              <input v-model="showCells" type="checkbox" class="btn-check" id="btncheck1" autocomplete="off">
              <label class="btn btn-outline-secondary" :class="showCells ? 'text-light' : ''" for="btncheck1">Afficher les cases séléctionnées</label>
              <input v-model="showGrid" type="checkbox" class="btn-check" id="btncheck3" autocomplete="off">
              <label class="btn btn-outline-secondary" :class="showGrid ? 'text-light' : ''" for="btncheck3">Afficher la grille</label>
            </div>
          </div>
        </div>
        <div class="row mt-3" style="height: 94%;">
          <div class="col-12 position-relative px-0">
            <div v-if="wallConfig.backgroundColor" style="top: 0; left: 0;" class="position-absolute w-100 h-100" :style="{ backgroundColor: wallConfig.backgroundColor.hex }"></div>
            <img v-if="wallConfig.backgroundImage && wallConfig.backgroundImage !== ''" style="top: 0; left: 0;"  class="position-absolute w-100 h-100" :src="wallConfig.backgroundImage" alt="backgroundImage"/>
            <img v-if="wallConfig.maskImage && wallConfig.maskImage !== ''" style="top: 0; left: 0;" class="position-absolute w-100 h-100" :src="wallConfig.maskImage" alt="maskImage"/>
            <div v-if="wallConfig.gridSize" class="w-100 h-100 position-relative" style="z-index: 10;">
              <table class="w-100 h-100">
                <tbody class="w-100 h-100">
                <tr v-for="y in rowCount" :key="`row_${y}`" class="grid-row">
                  <td v-for="x in parseInt(wallConfig.gridSize, 10)" :id="`cell_${y}_${x}`" :key="`cell_${y}_${x}`" :style="cellStyle" class="grid-cell" :class="isSelected(x, y) && showCells ? 'selected' : ''" @click.left="logClick(x, y, true)" @mouseover.left="logClick(x, y, false)"></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </template>
    </card>
  </modal>
</template>

<script>

import _ from 'lodash';
import VueNotifications from 'vue-notifications';
import card from '../Utilities/Card.vue';

export default {
  name: 'GridEditor',
  components: {
    card,
  },
  model: {
    prop: 'wallConfig',
    event: 'change',
  },
  props: {
    wallConfig: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      id: '',
      showGrid: true,
      showCells: true,
      mouseDown: false,
      selectedCells: [],
    };
  },
  created() {
    document.body.onmousedown = (evt) => {
      if (evt.button === 0)
        this.mouseDown = true;
    };
    document.body.onmouseup = (evt) => {
      if (evt.button === 0)
        this.mouseDown = false;
    };
  },
  mounted()
  {
    if (this.id === undefined || this.id === '') {
      this.id = `gridEditorModal${this.$chance.string({
        length: 5,
        symbols: false,
        casing: 'lower',
        numeric: true,
        alpha: true,
      })}`;
    }
  },
  computed: {
    rowCount() {
      return Math.ceil(this.wallConfig.gridSize / 1.77);
    },
    cellStyle() {
      return {
        border: (this.showGrid) ? 'solid 0.5px' : '',
      };
    },
  },
  methods: {
    autoDetect()
    {
      // TODO
    },
    selectAll()
    {
      this.wallConfig.selectedCells = [];
      for (let i = 0; i < this.wallConfig.gridSize * this.rowCount; i = i + 1)
        this.selectedCells.push(i);
    },
    deselectAll()
    {
      this.selectedCells.splice(0, this.selectedCells.length);
    },
    save()
    {
      this.$emit('change', { ...this.wallConfig, selectedCells: this.selectedCells });
      this.configSaved();
    },
    getPositionFromCoord(x, y)
    {
      return (y - 1) * this.wallConfig.gridSize + (x - 1);
    },
    isSelected(x, y)
    {
      if (this.selectedCells === undefined)
        return false;
      return this.selectedCells.includes(this.getPositionFromCoord(x, y));
    },
    logClick(x, y, click)
    {
      if (!click && !this.mouseDown)
        return;
      if (!this.selectedCells.includes(this.getPositionFromCoord(x, y)))
        this.selectedCells.push(this.getPositionFromCoord(x, y));
      else
      {
        const index = this.selectedCells.indexOf(this.getPositionFromCoord(x, y));
        if (index > -1)
          this.selectedCells.splice(index, 1);
      }
    },
    open() {
      this.selectedCells = _.cloneDeep(this.wallConfig.selectedCells || []);
      this.$modal.show(this.id);
    },
    close() {
      this.$modal.hide(this.id);
    },
  },
  notifications: {
    configSaved: {
      type: VueNotifications.types.success,
      title: 'Configuration sauvegardé',
      message: 'Votre configuration a bien été sauvegardé et appliquée !',
    },
  },
};

</script>

<style>

.grid-cell {
  background-color: transparent;
  /*border: solid 0.5px;*/
}

.grid-cell:hover {
  background-color: limegreen;
}

.grid-cell.selected {
  background-color: #00ff0055;
}

.grid-cell.selected:hover {
  background-color: red;
}

</style>
