<template>
  <div class="h-100 d-flex justify-content-center align-items-center align-middle">
    <div class="row w-100 text-center">
      <div class="col-12 mb-5">
        <h1 class="title">Éditeur de social wall Captag</h1>
      </div>
      <div class="col-12 mb-5">
        <img src="https://c.tenor.com/sjnrOgJ_uagAAAAC/cute-cat-crying.gif" alt="errorGif">
      </div>
      <div class="col-12">
        <h1 class="title">{{lastError.code}}</h1>
      </div>
      <div class="col-12 mb-5">
        <h5 class="subtitle">{{lastError.message}}</h5>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'ErrorPage',
  computed: {
    ...mapGetters([
      'lastError',
    ]),
  },
};
</script>

<style scoped>

.title {
  font-size: 70px;
}

.subtitle {
  font-size: 30px;
}

</style>
