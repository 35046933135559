<template>
  <form @submit.stop.prevent>
    <div class="mb-3">
      <label for="columnsInput" class="form-label">Nombre de colonnes de la mosaïque</label>
      <div class="input-group">
        <input v-model="gridSize" id="columnsInput" class="form-control" type="number" aria-describedby="rowsInformations">
        <button @click="$refs.gridEditor.open()" class="btn btn-info text-light"><i class="fas fa-pen me-2"></i>Editer la grille</button>
      </div>
      <div id="rowsInformations" class="form-text">Nombre de ligne : {{ Math.ceil(gridSize / 1.77) }} <span class="small mx-2">(16:9)</span>Nombre de cases : {{ Math.ceil(gridSize / 1.77) * gridSize }}</div>
    </div>
    <div class="mb-3">
      <InputColorPicker @change="setProp('backgroundColor', $event)" :value="getProp('backgroundColor', {})" label="Couleur de fond"/>
    </div>
    <div class="row mb-3">
      <div class="col">
        <InputImageUpload title="Image de fond" @change="setProp('backgroundImage', $event)" :value="getProp('backgroundImage', '')"/>
      </div>
      <div class="col">
        <InputImageUpload title="Image de masque" @change="setProp('maskImage', $event)" :value="getProp('maskImage', '')"/>
      </div>
    </div>
    <div class="collapse" id="collapseAdvParameters">
      <div class="mb-3 form-check form-switch">
        <label class="form-check-label" for="hoverZoom">Zoom au survol des images</label>
        <input @change="setProp('hoverZoom', $event.target.checked)" :value="getProp('hoverZoom', false)" class="form-check-input" type="checkbox" id="toggle-hoverZoom">
      </div>
      <div class="mb-3 form-check form-switch">
        <label class="form-check-label" for="newImagesOnly">Prendre uniquement les nouvelles images</label>
        <input @change="setProp('newMediaOnly', $event.target.checked)" :value="getProp('newMediaOnly', false)" class="form-check-input" type="checkbox" id="newImagesOnly">
      </div>
      <div class="mb-3">
        <label class="form-label">Pré-remplissage de la mosaique</label>
        <slider :formater="(value) => `${value}%`" :min="0" :max="100" @change="setProp('prefill', $event)" :value="getProp('prefill', 0)"/>
      </div>
      <div class="mb-3">
        <label class="form-label">Frequence d'apparition des photos</label>
        <slider :min="0" :max="4000" :formater="(value) => `${value} ms`" @change="setProp('spawnFreq', $event)" :value="getProp('spawnFreq', 2000)"/>
      </div>
      <div class="mb-3">
        <label class="form-label">Vitesse du Fade In/Out</label>
        <slider :min="0" :max="1200" :formater="(value) => `${value} ms`" @change="setProp('fadeSpeed', $event)" :value="getProp('fadeSpeed', 600)"/>
      </div>
      <div class="mb-3">
        <label class="form-label">Vitesse de déplacement</label>
        <slider :min="0" :max="5600" :formater="(value) => `${value} ms`" @change="setProp('moveSpeed', $event)" :value="getProp('moveSpeed', 2800)"/>
      </div>
      <div class="mb-3">
        <label class="form-label">Temps d'attente des photos</label>
        <slider :min="0" :max="4000" :formater="(value) => `${value} ms`" @change="setProp('waitingTime', $event)" :value="getProp('waitingTime', 500)"/>
      </div>
      <div class="mb-3">
        <label class="small font-weight-bold bmd-label-static" for="endBehavior">Comportement de fin</label>
        <select @change="setProp('endBehavior', $event.target.value)" :value="getProp('endBehavior', 'reload')" id="endBehavior" class="form-control">
          <option value="nothing">Ne rien faire</option>
          <option value="reload">Recharger Après</option>
        </select>
      </div>
      <div v-if="getProp('endBehavior', 'reload') === 'reload'" class="mb-3">
        <label class="form-label">Temps d'attente avant le rechargement</label>
        <slider :min="0" :max="10000" :formater="(value) => `${value} ms`" @change="setProp('finalTime', $event)" :value="getProp('finalTime', 5000)"/>
      </div>
    </div>
    <div class="row">
      <div class="col-auto text-center">
        <button class="btn btn-secondary text-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAdvParameters" aria-expanded="false" aria-controls="collapseAdvParameters"><i class="fas fa-cogs me-2"></i>Options avancée</button>
      </div>
      <div class="col-auto text-center">
        <button @click="setDefaultConfig" class="btn btn-danger text-light"><b><i class="fas fa-trash me-2"></i>Effacer la configuration</b></button>
      </div>
      <div class="col text-center btn-group">
        <button @click="exportConfig" class="btn btn-info text-light"><b><i class="fas fa-download me-2"></i>Exporter la configuration</b></button>
        <button @click="$refs.importHiddenInput.click()" class="btn btn-primary text-light"><b><i class="fas fa-upload me-2"></i>Importer une configuration</b></button>
      </div>
      <input accept="application/JSON" ref="importHiddenInput" type="file" v-on:change="importConfig" style="visibility: hidden">
    </div>
    <GridEditor ref="gridEditor" :wallConfig="photoWallConfig" @change="setProp('selectedCells', $event.selectedCells)"/>
  </form>
</template>

<script>

import InputColorPicker from '../Utilities/InputColorPicker.vue';
import InputImageUpload from '../Utilities/InputImageUpload.vue';
import defaultConfigFile from '../../Configuration/DefaultPhotowall.json';
import slider from '../Utilities/Slider.vue';
import GridEditor from './GridEditor.vue';

export default {
  name: 'photowallConfig',
  components: {
    GridEditor,
    InputImageUpload,
    InputColorPicker,
    slider,
  },
  props: {
    photoWallConfig: {
      type: Object,
      required: true,
    },
  },
  model: {
    prop: 'photoWallConfig',
    event: 'change',
  },
  methods: {
    setProp(prop, newValue)
    {
      const out = { ...this.photoWallConfig };
      out[prop] = newValue;
      this.$emit('change', out);
    },
    getProp(prop, defaultValue)
    {
      return this.photoWallConfig[prop] || defaultValue;
    },
    setDefaultConfig()
    {
      this.$emit('change', defaultConfigFile);
    },
    exportConfig()
    {
      const jsonData = JSON.stringify(this.photoWallConfig || {}, null, 4);
      const blob = new Blob([jsonData], { type: 'text/plain' });
      const e = document.createEvent('MouseEvents');
      const a = document.createElement('a');
      a.download = `photowall_${this.$chance.string({
        length: 5,
        symbols: false,
        casing: 'lower',
        numeric: true,
        alpha: true,
      })}.json`;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    },
    importConfig(ev)
    {
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const object = JSON.parse(e.target.result);
          if (object !== undefined)
          {
            this.$emit('change', object);
          }
        } catch (ex)
        {
          console.log(`Invalid website content file : ${ex.message}`);
        }
      };
      reader.readAsText(ev.target.files[0]);
    },
  },
  computed: {
    gridSize: {
      get()
      {
        return this.photoWallConfig.gridSize || 0;
      },
      set(newGridSize)
      {
        this.$emit('change', { ...this.photoWallConfig, gridSize: newGridSize });
      },
    },
  },
};

</script>

<style scoped>

</style>
