<template>
  <form @submit.stop.prevent>
    <div class="mb-3">
      <label for="columnsInput" class="form-label">Nombre de colonnes de la mosaïque</label>
      <input id="columnsInput" class="form-control" type="number" @change="setProp('columnsCount', parseInt($event.target.value, 10))" :value="getProp('columnsCount', 3)">
    </div>
    <div class="mb-3">
      <InputColorPicker @change="setProp('backgroundColor', $event)" :value="getProp('backgroundColor', {})" label="Couleur de fond"/>
    </div>
    <div class="mb-3">
      <InputImageUpload title="Image de fond" @change="setProp('backgroundImage', $event)" :value="getProp('backgroundImage', '')"/>
    </div>
    <div class="mb-3">
      <InputColorPicker @change="setProp('cardBackgroundColor', $event)" :value="getProp('cardBackgroundColor', {})" label="Couleur de fond des cartes"/>
    </div>
    <div class="mb-3">
      <FontSelector label="Police" @change="setProp('fontFamily', $event)" :value="getProp('fontFamily', '')" :importedFonts="googleFonts"/>
    </div>
    <div class="mb-3">
      <BorderEditor @change="setProp('cardBorder', $event)" :value="getProp('cardBorder', {})" label="Bordure des cartes"/>
      <label class="form-label">Rondeur du bord des cards</label>
      <slider :min="0" :max="50" :formater="(value) => `${value} px`" @change="setProp('borderRadius', $event)" :value="getProp('borderRadius', 25)"/>
    </div>
    <div class="mb-3">
      <spacingEditor title="Marges des cartes" @change="setProp('cardSpacings', $event)" :value="getProp('cardSpacings', {})"/>
    </div>
    <div class="mb-3 form-check form-switch">
      <label class="form-check-label" for="displayMessage">Afficher le message du post</label>
      <input :checked="getProp('displayMessage', true) ? true : undefined" @change="setProp('displayMessage', $event.target.checked)" :value="getProp('displayMessage', true)" class="form-check-input" type="checkbox" id="displayMessage">
    </div>
    <div class="mb-3 row" v-if="getProp('displayMessage', true)">
      <div class="col-md">
        <label for="fontSizeInput" class="form-label">Taille de police du message</label>
        <input id="fontSizeInput" class="form-control" type="number" @change="setProp('messageFontSize', parseInt($event.target.value, 10))" :value="getProp('messageFontSize', 22)">
      </div>
      <div class="col-md">
        <label class="form-label">Alignement du text</label>
        <select @change="setProp('messageAlignment', $event.target.value)" :value="getProp('messageAlignment', 'left')"  class="form-select">
          <option value="left">Gauche</option>
          <option value="right">Droite</option>
          <option value="center">Centre</option>
        </select>
      </div>
      <div class="col-md">
        <div class="row">
          <div class="col-12 mb-2"><label>Couleur des messages</label></div>
          <div class="col-12"><InputColorPicker @change="setProp('messageColor', $event)" :value="getProp('messageColor', {})"/></div>
        </div>
      </div>
    </div>
    <div class="mb-2">
      <hr>
      <label>Information de l'auteur</label>
    </div>
    <div class="mb-2">
      <div id="featuresBtnGroup" class="btn-group w-100" role="group">
        <input @change="setProp('displayAuthor', $event.target.checked)" :checked="getProp('displayAuthor', true) ? true : undefined" :value="getProp('displayAuthor', true)" type="checkbox" class="btn-check" id="authorBtnCheck" autocomplete="off">
        <label class="btn btn-outline-success" for="authorBtnCheck" :class="getProp('displayAuthor', true) ? 'text-light' : ''"><i class="fas fa-feather me-3"></i>Afficher l'auteur du post</label>
        <input @change="setProp('displayTime', $event.target.checked)" :checked="getProp('displayTime', true) ? true : undefined" :value="getProp('displayTime', true)" type="checkbox" class="btn-check" id="dateBtnCheck" autocomplete="off">
        <label class="btn btn-outline-success" for="dateBtnCheck" :class="getProp('displayTime', true) ? 'text-light' : ''"><i class="fas fa-clock me-3"></i>Afficher la date du post</label>
        <input @change="setProp('displayAvatar', $event.target.checked)" :checked="getProp('displayAvatar', true) ? true : undefined" :value="getProp('displayAvatar', true)" type="checkbox" class="btn-check" id="avatarBtnCheck" autocomplete="off">
        <label class="btn btn-outline-success" for="avatarBtnCheck" :class="getProp('displayAvatar', true) ? 'text-light' : ''"><i class="fas fa-user me-3"></i>Afficher l'avatar de l'auteur</label>
      </div>
    </div>
    <div class="row" v-if="getProp('displayAuthor', true) || getProp('displayTime', true) || getProp('displayAvatar', true)">
      <div class="col-md" v-if="getProp('displayAuthor', true)">
        <div class="mb-3">
          <label for="fontSizeInput" class="form-label">Taille de police de l'auteur</label>
          <input id="fontSizeInput" class="form-control" type="number" @change="setProp('authorFontSize', parseInt($event.target.value, 10))" :value="getProp('authorFontSize', 20)">
        </div>
      </div>
      <div class="col-md">
        <div class="mb-3">
          <label class="form-label">Alignement des text de l'auteur</label>
          <select @change="setProp('authorInfoAlignment', $event.target.value)" :value="getProp('authorInfoAlignment', 'left')"  class="form-select">
            <option value="left">Gauche</option>
            <option value="right">Droite</option>
            <option value="center">Centre</option>
          </select>
        </div>
      </div>
      <div class="col-md">
        <div class="row">
          <div class="col-12 mb-2"><label>Couleur de l'auteur</label></div>
          <div class="col-12"><InputColorPicker @change="setProp('authorColor', $event)" :value="getProp('authorColor', {})"/></div>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md">
        <label class="form-label">Frequence d'update</label>
        <slider :min="2" :max="10" :formater="(value) => `${value} s`" @change="setProp('updateFreq', $event)" :value="getProp('updateFreq', 5)"/>
      </div>
      <div class="col-md">
        <label class="form-label">Vitesse d'apparition des cartes</label>
        <slider :min="1" :max="10" :formater="(value) => `${value} s`" @change="setProp('animationSpeed', $event)" :value="getProp('animationSpeed', 2)"/>
      </div>
    </div>
    <div class="row">
      <div class="col-auto text-center">
        <button @click="setDefaultConfig" class="btn btn-danger text-light"><b><i class="fas fa-trash me-2"></i>Effacer la configuration</b></button>
      </div>
      <div class="col text-center btn-group">
        <button @click="exportConfig" class="btn btn-info text-light"><b><i class="fas fa-download me-2"></i>Exporter la configuration</b></button>
        <button @click="$refs.importHiddenInput.click()" class="btn btn-primary text-light"><b><i class="fas fa-upload me-2"></i>Importer une configuration</b></button>
      </div>
      <input accept="application/JSON" ref="importHiddenInput" type="file" v-on:change="importConfig" style="visibility: hidden">
    </div>
  </form>
</template>

<script>

import { mapGetters } from 'vuex';
import InputColorPicker from '../Utilities/InputColorPicker.vue';
import InputImageUpload from '../Utilities/InputImageUpload.vue';
import defaultConfigFile from '../../Configuration/DefaultSocialWall.json';
import BorderEditor from '../Utilities/BorderEditor.vue';
import slider from '../Utilities/Slider.vue';
import FontSelector from '../Utilities/FontSelector.vue';
import SpacingEditor from '../Utilities/SpacingEditor.vue';

export default {
  name: 'socialWallConfig',
  components: {
    SpacingEditor,
    FontSelector,
    BorderEditor,
    InputColorPicker,
    InputImageUpload,
    slider,
  },
  props: {
    photoWallConfig: {
      type: Object,
      required: true,
    },
  },
  model: {
    prop: 'photoWallConfig',
    event: 'change',
  },
  computed: {
    ...mapGetters(['googleFonts']),
  },
  methods: {
    setProp(prop, newValue)
    {
      const out = { ...this.photoWallConfig };
      out[prop] = newValue;
      this.$emit('change', out);
    },
    getProp(prop, defaultValue)
    {
      return (this.photoWallConfig[prop] !== undefined) ? this.photoWallConfig[prop] : defaultValue;
    },
    setDefaultConfig()
    {
      this.$emit('change', defaultConfigFile);
    },
    exportConfig()
    {
      const jsonData = JSON.stringify(this.photoWallConfig || {}, null, 4);
      const blob = new Blob([jsonData], { type: 'text/plain' });
      const e = document.createEvent('MouseEvents');
      const a = document.createElement('a');
      a.download = `socialWall_${this.$chance.string({
        length: 5,
        symbols: false,
        casing: 'lower',
        numeric: true,
        alpha: true,
      })}.json`;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    },
    importConfig(ev)
    {
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const object = JSON.parse(e.target.result);
          if (object !== undefined)
          {
            this.$emit('change', object);
          }
        } catch (ex)
        {
          console.log(`Invalid website content file : ${ex.message}`);
        }
      };
      reader.readAsText(ev.target.files[0]);
    },
  },
};

</script>

<style scoped>

</style>
