<template>
  <div v-if="Object.keys(sources).length <= 0" class="container-fluid">
    <div class="row mt-5">
      <div class="col-auto mx-auto">
        <h3 class="text-muted">Pas de sources pour le moment</h3>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-auto mx-auto">
        <button @click="$refs.newSourceModal.open()" class="btn btn-success btn-lg text-light"><h4 class="pt-2"><i class="fas fa-plus me-3"></i>Ajouter une source</h4></button>
        <sourceEditorModal :baseSourceConfig="{}" ref="newSourceModal" @change="$store.commit('addSource', { newSource: $event, dbSync: true })"/>
        <sourceEditorModal :baseSourceConfig="{}" ref="newSourceModal" @change="$store.commit('addSource', { newSource: $event, dbSync: true })"/>
      </div>
    </div>
  </div>
  <div v-else class="row">
    <div class="col-12">
      <label class="form-label">Liste des sources</label>
    </div>
    <div class="col-12">
      <li class="list-group-item" v-for="(source, sourceID) in sources" :key="`source-${sourceID}`">
        <div class="row">
          <div class="col pe-0">
            <div class="input-group mb-1">
              <span class="input-group-text"><i class="fas fa-tags"></i></span>
              <input disabled :value="source.name" class="form-control">
              <span class="input-group-text"><i class="fas fa-database"></i></span>
              <input disabled :value="source.type" class="form-control">
            </div>
          </div>
          <div class="col-auto d-flex align-items-center">
            <div class="btn-group" role="group" aria-label="Basic example">
              <button @click="$store.commit('removeSource', { sourceID, dbSync: true})" class="btn btn-danger text-center">
                <i class="fas fa-trash text-light"></i>
              </button>
              <button @click="$refs[`sourceModalEditor-${sourceID}`][0].open()" class="btn btn-info text-center">
                <i class="fas fa-pen text-light"></i>
              </button>
            </div>
          </div>
        </div>
        <sourceEditorModal :baseSourceConfig="source" :ref="`sourceModalEditor-${sourceID}`" @change="$store.commit('setSourceConfig', { sourceID, sourceConfig: $event, dbSync: true })"/>
      </li>
    </div>
    <div class="col-12 mt-4">
      <div class="row">
        <div class="col-auto ms-auto">
          <button @click="$refs.newSourceModal.open()" class="btn btn-success text-light"><i class="fas fa-plus me-2"></i>Nouvelle source</button>
        </div>
        <div class="col-auto me-auto">
          <button class="btn btn-danger text-light"><i class="fas fa-trash me-2"></i>Supprimer les sources</button>
        </div>
        <sourceEditorModal :baseSourceConfig="{}" ref="newSourceModal" @change="$store.commit('addSource', { newSource: $event, dbSync: true })"/>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import sourceEditorModal from './sourceEditorModal.vue';

export default {
  name: 'SourcesEditor',
  components: { sourceEditorModal },
  computed: {
    ...mapGetters(['sources']),
  },
};

</script>

<style scoped>

</style>
