import Vue from 'vue';
import Vuex from 'vuex';
import { vuexfireMutations } from 'vuexfire';
import mutations from './mutations';
import actions from './actions';
import state from './state';
import getters from './getters';

Vue.use(Vuex);

/**
 * Program Store Object
 * @type {Store<unknown>}
 */
const vuex = new Vuex.Store({
  strict: false,
  state,
  mutations: {
    ...mutations,
    ...vuexfireMutations,
  },
  actions,
  getters,
});

export default vuex;
