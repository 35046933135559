<template>
  <modal :name="id" width="50%" height="60%" v-on:scroll.prevent>
    <card title="<i class='fas fa-plus me-2'></i>Nouvelle source" class="h-100">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <label class="form-label" for="nameInput">Nom de votre source</label>
            <input v-model="sourceConfig.name" class="form-control" type="text" id="nameInput">
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12 mt-4">
            <label class="text-center form-label">Type de source</label>
          </div>
          <div class="col">
            <button @click="setType('flockler')" :class="sourceConfig.type === 'flockler' ? 'btn-success text-light' : 'btn-outline-secondary'" class="w-100 btn py-4 disabled"><i class="fas fa-hashtag fa-2x mb-2"></i><br>Flockler</button>
          </div>
          <div class="col">
            <button @click="setType('photobooth')" :class="sourceConfig.type === 'photobooth' ? 'btn-success text-light' : 'btn-outline-secondary'" class="w-100 btn py-4"><i class="fas fa-camera fa-2x mb-2"></i><br>Photobooth</button>
          </div>
          <div class="col">
            <button @click="setType('userdata')" :class="sourceConfig.type === 'userdata' ? 'btn-success text-light' : 'btn-outline-secondary'" class="w-100 btn py-4"><i class="fas fa-user fa-2x mb-2"></i><br>Donnée utilisateur</button>
          </div>
          <div class="col">
            <button @click="setType('webapp')" :class="sourceConfig.type === 'webapp' ? 'btn-success text-light' : 'btn-outline-secondary'" class="w-100 btn py-4"><i class="fas fa-comments fa-2x mb-2"></i><br>Mur d'échange</button>
          </div>
        </div>
        <div v-if="sourceConfig.type === 'flockler'" class="row">
          <div class="col-12">
            <label class="form-label" for="flockerIDInput">ID de site Flockler</label>
            <input v-model="sourceConfig.flocklerID" class="form-control" id="flockerIDInput" type="text">
          </div>
        </div>
        <div v-if="sourceConfig.type === 'photobooth'" class="row">
          <div class="col-12">
            <label class="form-label">Animation du photobooth</label>
            <select class="form-select" v-model="sourceConfig.photoboothAnimation">
              <option disabled value="">Veuillez choisir une animation</option>
              <option v-for="(anim, animID) in animations" :value="animID" :key="animID">{{ anim.name || animID }}</option>
            </select>
          </div>
        </div>
        <div v-if="sourceConfig.type === 'userdata'" class="row">
          <div class="col-12">
            <label class="form-label">Donnée Utilisateur</label>
            <select class="form-select" v-model="sourceConfig.userData">
              <option disabled value="">Veuillez choisir une animation</option>
              <option v-for="(data, dataID) in datas" :value="dataID" :key="dataID">{{ data.name || dataID }}</option>
            </select>
          </div>
        </div>
        <div v-if="sourceConfig.type === 'webapp'" class="row">
          <div class="col-12">
            <label class="form-label">Module de la webapp</label>
            <select class="form-select" v-model="sourceConfig.webappModulePath">
              <option disabled value="">Veuillez choisir un module</option>
              <optgroup v-for="(webapp, webappID) in webappModules" :key="webappID" :label="webappID">
                <option v-for="(module, moduleID) in webapp" :key="moduleID" :value="`${webappID}/${moduleID}`">{{ module || moduleID }}</option>
              </optgroup>
            </select>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-auto mx-auto">
            <button @click="submit" :disabled="isConfigValid ? undefined : true" :class="isConfigValid ? '' : 'disabled'" class="btn btn-success text-light btn-lg">Valider</button>
          </div>
        </div>
      </div>
    </card>
  </modal>
</template>

<script>

import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import Card from '../Utilities/Card.vue';

export default {
  name: 'sourceEditorModal',
  components: {
    Card,
  },
  props: {
    baseSourceConfig: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      id: '',
      webappModules: {},
      sourceConfig: {
        type: '',
        name: '',
        flocklerID: '',
        photoboothAnimation: '',
        userData: '',
        webappModulePath: '',
      },
    };
  },
  computed: {
    ...mapGetters(['animations', 'datas']),
    isConfigValid()
    {
      if (!this.sourceConfig.name || this.sourceConfig.name === '')
        return false;
      if (!this.sourceConfig.type || this.sourceConfig.type === '')
        return false;
      switch (this.sourceConfig.type)
      {
        case 'flockler':
          if (!this.sourceConfig.flocklerID || this.sourceConfig.flocklerID === '') return false;
          break;
        case 'photobooth':
          if (!this.sourceConfig.photoboothAnimation || this.sourceConfig.photoboothAnimation === '') return false;
          break;
        case 'userdata':
          if (!this.sourceConfig.userData || this.sourceConfig.userData === '') return false;
          break;
        case 'webapp':
          if (!this.sourceConfig.webappModulePath || this.sourceConfig.webappModulePath === '') return false;
          break;
        default:
          break;
      }
      return true;
    },
  },
  mounted()
  {
    if (this.id === undefined || this.id === '') {
      this.id = `newSourceEditorModal${this.$chance.string({
        length: 5,
        symbols: false,
        casing: 'lower',
        numeric: true,
        alpha: true,
      })}`;
    }
  },
  methods: {
    async getWebappModules()
    {
      const modules = await this.$store.dispatch('getWebappModules');
      if (modules)
        this.webappModules = modules;
    },
    setType(type)
    {
      Vue.set(this.sourceConfig, 'type', type);
    },
    submit()
    {
      this.$emit('change', this.sourceConfig);
      this.close();
    },
    open() {
      this.getWebappModules();
      this.sourceConfig = _.cloneDeep(this.baseSourceConfig);
      this.$modal.show(this.id);
    },
    close() {
      this.$modal.hide(this.id);
    },
  },
};

</script>

<style scoped>

</style>
