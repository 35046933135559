import Vue from 'vue';
import { uuid } from 'vue-uuid';

const Chance = require('chance');

const mutations = {
  /**
   * Set the firebase token ID
   * @param state Vuex state (do not pass for users)
   * @param tokenID: the token to set
   */
  setTokenId(state, { tokenID })
  {
    tokenID.trim();
    state.tokenID = tokenID;
  },

  /**
   * Set the website path on firebase
   * @param state Vuex state (do not pass for users)
   * @param path: the website path
   */
  setPath(state, { path })
  {
    state.path = path;
  },

  /**
   * Toggle the page loading screen
   * @param state state Vuex state (do not pass for users)
   * @param value: Boolean [true;false]
   */
  toggleLoading(state, { value })
  {
    state.loading = value;
  },

  /**
   * Toggle the error page
   * @param state state Vuex state (do not pass for users)
   * @param error: true to show the page
   * @param content: the error message
   */
  toggleLoadingError(state, { error, content })
  {
    state.loadingError = error;
    state.lastError = content;
  },

  /**
   * Set the version number.
   * This value is displayed on the bottom of the advanced configuration
   * @param state Vuex state (do not pass for users)
   * @param version: the version number, a string
   */
  setVersionNumber(state, { version })
  {
    state.versionNumber = version;
  },

  /**
   * Display the firebase sync spinner when set to true
   * @param state Vuex state (do not pass for users)
   * @param value: [true; false]
   */
  toggleFirebaseSpinner(state, { value })
  {
    state.firebaseSpinner = value;
  },

  setWallConfig(state, { config })
  {
    state.wallConfigData.config = config;
  },

  addSource(state, { newSource })
  {
    const chance = new Chance();
    const newID = chance.string({
      length: 5,
      casing: 'upper',
      alpha: true,
      numeric: false,
    });
    if (!state.wallConfigData.config)
      Vue.set(state.wallConfigData, 'config', {});
    if (!state.wallConfigData.config.sources)
      state.wallConfigData.config.sources = {};
    state.wallConfigData.config.sources[newID] = newSource;
    return newID;
  },

  setSourceConfig(state, { sourceID, sourceConfig })
  {
    state.wallConfigData.config.sources[sourceID] = sourceConfig;
  },

  removeSource(state, { sourceID })
  {
    Vue.delete(state.wallConfigData.config.sources, sourceID);
  },
  /**
   *
   * @param state
   * @param color: The color code you want to add to the palette
   */
  addColorToPalette(state, { color })
  {
    console.log(`add color to palette : ${color}`);
    if (!state.wallConfigData.config.colorPalette)
      state.wallConfigData.config.colorPalette = [];
    if (!state.wallConfigData.config.colorPalette.includes(color))
      state.wallConfigData.config.colorPalette.push(color);
  },
  /**
   * Add a Custom font to the website list.
   * An uuid will be created for this font
   * TODO rename from 'GoogleFont' to 'CustomFont' everywhere
   * @param state Vuex state (do not pass for users)
   * @param fontObject: The new font Data
   */
  addGoogleFont(state, { fontObject })
  {
    if (state.wallConfigData.config.googleFonts === undefined)
      state.wallConfigData.config.googleFonts = {};
    Vue.set(state.wallConfigData.config.googleFonts, uuid.v4(), fontObject);
  },
  /**
   * Remove a Custom font from the website list
   * identified by he's ID
   * @param state state Vuex state (do not pass for users)
   * @param fontID: The ID of the removed font
   */
  deleteGoogleFont(state, { fontID })
  {
    Vue.delete(state.wallConfigData.config.googleFonts, fontID);
  },
  /**
   * Toggle the user defined custom CSS
   * @param state state Vuex state (do not pass for users)
   * @param value: the CSS to set
   */
  setCustomCSS(state, { value })
  {
    state.wallConfigData.config.customCSS = value;
  },
};

export default mutations;
