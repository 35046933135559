/* eslint-disable no-shadow */// No shadow due to the getters parameter to getters

const getters = {
  /// The website path in firebase
  path: (state) => state.path,
  // The eventID
  eventID: (state) => state.path.substring(0, state.path.indexOf('/')),
  // Is the page loading
  isLoading: (state) => state.loading,
  // Is there error on the website loading
  isLoadingError: (state) => state.loadingError,
  // Last loading error
  lastError: (state) => state.lastError,
  // Firebase token ID
  tokenID: (state) => state.tokenID,
  // Firebase access token
  accessToken: (state) => state.accessToken,
  // Current wall name
  wallName: (state) => state.wallConfigData.name || '',
  wallConfig: (state) => state.wallConfigData.config || {},
  customCSS: (state) => {
    if (state.wallConfigData.config)
      return state.wallConfigData.config.customCSS || '';
    return '';
  },
  sources: (state) => {
    if (state.wallConfigData.config)
      return state.wallConfigData.config.sources || {};
    return {};
  },
  animations: (state) => state.animations || {},
  datas: (state) => state.datas || {},
  colorPalette: (state) => state.wallConfigData.config.colorPalette || [],
  googleFonts: (state) => {
    if (state.wallConfigData.config)
      return state.wallConfigData.config.googleFonts || {};
    return {};
  },
};

export default getters;

/* eslint-enable no-shadow */
