<template>
  <modal v-on:closed="onClose" :name="id" :width="'90%'" :height="'90%'" :styles="{ overflow: 'initial' }" ref="modal">
    <card class="h-100" :showFooter="true">
      <template v-slot:header-left><h4 class="text-white mb-0" v-html="title"></h4></template>
      <template v-slot:header-right>
        <button type="button" class="btn-close btn-close-white" aria-label="Close" @click="close()"></button>
      </template>
      <div class="row h-100" style="overflow-y: hidden;">
        <div class="h-100" :class="(preview && displayPreview) ? 'col-6' : 'col-12'" style="text-align:start">
          <Tabs v-bind:tabs="tabs" class="h-100">
            <template v-slot:slot-0>
              <div class="row h-100">
                <div class="col-12 my-2">
                  <div class="row">
                    <div class="col-auto" v-if="(mode === 'text/css')">
                      <div class="dropdown">
                        <button class="btn btn-info dropdown-toggle text-light" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                          <i class="fas fa-directions me-2"></i>Bout de code CSS
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <li v-for="(snippet, snippetID) in cssSnippetList" :key="snippetID">
                            <a v-on:click="pastCssSnippet(snippet.content)" class="dropdown-item" href="#" v-html="snippet.title"></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-auto">
                      <select v-model="editorTheme" class="form-select" aria-label="Default select example">
                        <option selected>Choisissez un thème</option>
                        <option value="monokai">Monokai</option>
                        <option value="darcula">Darcula</option>
                      </select>
                    </div>
                    <div class="col-auto" v-if="preview">
                      <button v-if="!displayPreview" class="btn btn-primary btn-raised" v-on:click="displayPreview = !displayPreview">
                        <i class="fas fa-eye text-secondary me-2"></i><i class="fa fa-chevron-right pull-right text-black"></i>
                      </button>
                      <button v-else class="btn btn-primary btn-raised" v-on:click="displayPreview = !displayPreview">
                        <i class="fas fa-eye-slash text-secondary me-2"></i> <i class="fa fa-chevron-left pull-left text-black"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-12 h-100">
                  <codemirror ref="codeMirror" style="font-size: 11px;" v-model="localData" v-bind:options="{ ...cmOptions, theme: editorTheme }"/>
                </div>
              </div>
            </template>
            <template v-slot:slot-1>
              <div class="row mt-4">
                <div class="col-12 text-center">
                  <h4 class="text-muted">Les widouille arrivent ici...</h4>
                </div>
              </div>
            </template>
          </Tabs>
        </div>
        <div v-if="preview && displayPreview" class="col-6 h-100">
          <card class="h-100">
            <template v-slot:header-left>
              <h4 class="text-white m-0">Aperçu</h4>
            </template>
            <template v-slot:header-right>
              <div class="row">
                <div class="col-auto">
                  <label class="text-light">Couleur de fond}<span v-tooltip="'Prévisualisation uniquement'" class="badge rounded-pill bg-info text-white ms-2 small"><i class="fas fa-eye small"></i></span></label>
                </div>
                <div class="col">
                  <inputColorPicker v-model="backgroundColor"/>
                </div>
              </div>
            </template>
            <perfect-scrollbar class="h-100" :options="{ wheelPropagation: false, suppressScrollX: true }">
              <div v-bind:style="previewStyle" v-html="this.localData"></div>
            </perfect-scrollbar>
          </card>
        </div>
      </div>
      <template v-slot:footer>
        <div class="row my-2">
          <div class="col text-end">
            <button v-on:click="save" class="btn btn-success text-white"><i class="fas fa-save me-2"></i>SAUVEGARDER</button>
          </div>
        </div>
      </template>
    </card>
  </modal>
</template>

<script>

import VueNotifications from 'vue-notifications';
import { mapGetters } from 'vuex';
import { codemirror } from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/css/css';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/theme/darcula.css';
import 'codemirror/theme/monokai.css';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/selection/active-line';
import 'codemirror/addon/edit/closetag';
import 'codemirror/addon/scroll/simplescrollbars';
import 'codemirror/addon/scroll/simplescrollbars.css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/addon/search/search';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/hint/html-hint';
import 'codemirror/addon/hint/css-hint';
import 'codemirror/addon/search/matchesonscrollbar.css';
import 'codemirror/addon/search/searchcursor';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/edit/matchtags';
import 'codemirror/addon/edit/trailingspace';
import 'codemirror/addon/comment/comment';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/lint.css';
import 'codemirror/addon/lint/css-lint';
import 'codemirror/addon/dialog/dialog';
import 'codemirror/addon/dialog/dialog.css';
import 'codemirror/addon/display/autorefresh';
import Card from './Utilities/Card.vue';
import Tabs from './Utilities/Tabs.vue';
import InputColorPicker from './Utilities/InputColorPicker.vue';
import CssSnippet from '../Configuration/CssSnippet.json';

export default {
  name: 'CustomCodeEditorModal',
  components: {
    Card,
    Tabs,
    codemirror,
    InputColorPicker,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    preview: {
      type: Boolean,
      required: false,
      default: false,
    },
    previewBackgroundColor: {
      type: String,
      required: false,
      default: () => ('#ffffffff'),
    },
    mode: {
      type: [String, Object],
      required: false,
      default: 'text/html',
    },
    title: {
      type: String,
      required: false,
      default: 'Custom Code editor Modal',
    },
  },
  methods: {
    pastListenerSnippet(eventName)
    {
      this.localData = `${this.localData}\nwindow.addEventListener('${eventName}', function() {\n  \n});\n`;
    },
    pastCssSnippet(content)
    {
      this.localData = `${this.localData}\n${content}`;
    },
    onClose()
    {
      this.opened = false;
    },
    setLocalData()
    {
      this.localData = `${this.value}`; // copy
    },
    open()
    {
      if (!this.opened)
      {
        document.addEventListener('keydown', this.onKeyDown);
        this.setLocalData();
      }
      this.$modal.show(this.id);
      this.opened = true;
    },
    close()
    {
      this.opened = false;
      this.$modal.hide(this.id);
      document.removeEventListener('keydown', this.onKeyDown);
    },
    save()
    {
      this.$emit('input', this.localData);
      this.saveSuccess();
    },
    onKeyDown(ev)
    {
      if ((ev.keyCode === 83 && (ev.ctrlKey || ev.metaKey))) {
        ev.preventDefault();
        this.save();
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyDown);
  },
  created()
  {
    this.backgroundColor.hex8 = this.previewBackgroundColor;
  },
  mounted() {
    if (this.id === undefined || this.id === '')
    {
      this.id = `codeEditorModal${this.$chance.string({
        length: 5,
        symbols: false,
        casing: 'lower',
        numeric: true,
        alpha: true,
      })}`;
    }
  },
  computed: {
    tabs() {
      return [
        '<h5 class="m-0 text-secondary">Éditeur<i class="ms-3 fas fa-code"></i></h5>',
      ];
    },
    previewStyle()
    {
      if (this.backgroundColor === undefined)
        return { backgroundColor: '#ffffffff' };
      if (this.backgroundColor.hex8 !== undefined)
        return { backgroundColor: this.backgroundColor.hex8 };
      if (this.backgroundColor.rgba !== undefined)
        return { backgroundColor: `rgba(${this.backgroundColor.rgba.r},${this.backgroundColor.rgba.g},${this.backgroundColor.rgba.b},${this.backgroundColor.rgba.a},)` };
      return { backgroundColor: '#ffffffff' };
    },
    cssSnippetList()
    {
      return CssSnippet;
    },
  },
  data() {
    return {
      backgroundColor: {
        hex8: '#ffffffff',
      },
      id: '',
      displayPreview: true,
      editorTheme: 'monokai',
      localData: '',
      opened: false,
      cmOptions: {
        scrollbarStyle: 'simple',
        tabSize: 4,
        mode: this.mode,
        theme: 'monokai',
        lineNumbers: true,
        line: true,
        addons: 'show-hint',
        autoCloseTags: true,
        styleActiveLine: true,
        autofocus: true,
        lineWrapping: true,
        matchBrackets: true,
        autoCloseBrackets: true,
        enableSearchTools: true,
        enableCodeFolding: true,
        enableCodeFormatting: true,
        autoFormatOnStart: true,
        autoFormatOnModeChange: true,
        autoFormatOnUncomment: true,
        showSearchButton: false,
        showTrailingSpace: false,
        highlightMatches: true,
        showFormatButton: false,
        showCommentButton: false,
        showUncommentButton: false,
        showAutoCompleteButton: true,
        smartIndent: true,
        indentUnit: 2,
        foldGutter: true,
      },
    };
  },
  notifications: {
    saveSuccess: {
      type: VueNotifications.types.success,
      title: 'Code sauvegardé !',
      message: 'Votre code a été sauvegardé',
    },
  },
};

</script>

<style>

.vue-codemirror {
  height: 100%;
}

.CodeMirror-scroll {
  height: 100%;
}

.CodeMirror {
  height: 90%;
}

</style>
