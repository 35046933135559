<template>
  <div class="h-100 d-flex justify-content-center align-items-center align-middle">
    <div class="row w-100 text-center">
      <div class="col-12">
        <h1 class="title">Éditeur de social wall Captag</h1>
      </div>
      <div class="col-12 mb-5">
        <h5 class="subtitle">Chargement</h5>
      </div>
      <div class="col-12 mt-5 mb-3 d-flex justify-content-center align-items-center align-middle">
        <breeding-rhombus-spinner
          :animation-duration="2000"
          :size="150"
          color="#F16253"
        />
      </div>
      <div class="col-12 mt-5">
        <h3 class="text-secondary">Chargement en cours...</h3>
      </div>
    </div>
  </div>
</template>

<script>

import { BreedingRhombusSpinner } from 'epic-spinners';

export default {
  name: 'LoadingPage',
  components: {
    BreedingRhombusSpinner,
  },
  data() {
    return {
      showTimeoutMessage: false,
    };
  },
  created()
  {
    setInterval(() => { this.showTimeoutMessage = true; }, 5000);
  },
};

</script>

<style scoped>

.title {
  font-size: 70px;
}

.subtitle {
  font-size: 30px;
}
</style>
