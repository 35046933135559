<template>
  <div>
    <label class="small" for="backgroundPropertiesSelects">{{ label }}</label>
    <div class="row" id="backgroundPropertiesSelects">
      <div class="col pe-0">
        <input v-model="thickness" type="number" class="form-control" id="borderThickness">
      </div>
      <div class="col px-1">
        <select class="form-control" v-model="style">
          <option value="none">None</option>
          <option value="dotted">dotted</option>
          <option value="dashed">dashed</option>
          <option value="solid">solid</option>
          <option value="double">double</option>
          <option value="groove">groove</option>
          <option value="ridge">ridge</option>
          <option value="inset">inset</option>
          <option value="outset">outset</option>
        </select>
      </div>
      <div class="col ps-0">
        <InputColorPicker
            v-model="color"/>
      </div>
    </div>
  </div>
</template>

<script>

import InputColorPicker from './InputColorPicker.vue';

export default {
  name: 'BorderEditor',
  components: {
    InputColorPicker,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    thickness: {
      get()
      {
        return this.value.thickness || 0;
      },
      set(thickness)
      {
        this.$emit('change', { ...this.value, thickness });
      },
    },
    style: {
      get()
      {
        return this.value.style || 'none';
      },
      set(style)
      {
        this.$emit('change', { ...this.value, style });
      },
    },
    color: {
      get()
      {
        return this.value.color || {};
      },
      set(color)
      {
        this.$emit('change', { ...this.value, color });
      },
    },
  },
};

</script>

<style scoped>

</style>
