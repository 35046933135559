<template>
  <form v-on:submit.prevent="deploy">
    <div class="mb-3">
      <label class="small fw-bold " for="timestamp">Heure de déploiement</label>
      <input v-bind:value="formatDate" disabled id="timestamp" type="text" class="form-control required">
    </div>
    <div class="mb-3">
      <label class="small fw-bold ">Message</label>
      <textarea v-model="commit.message" name="message" type="text" class="form-control" placeholder="Pour garder une trace de vos modifications ..."></textarea>
    </div>
    <div class="row justify-content-center">
      <div class="col-auto">
        <button class="btn btn-raised text-light fw-bold btn-success" :disabled="!commitUpdated" v-bind:class="commitUpdated ? '' : 'disabled'"><i v-if="!commitUpdated" class="fas fa-circle-notch fa-spin me-2"></i>PUBLIER LE MUR</button>
      </div>
    </div>
  </form>
</template>

<script>

import VueNotifications from 'vue-notifications';
import { mapGetters } from 'vuex';
import crypto from 'crypto';
import { uuid } from 'vue-uuid';

export default {
  name: 'NewDeployment',
  data() {
    return {
      commitUpdated: false,
      commit: {
        id: '',
        message: '',
        content: '',
        date: 0,
        checksum: '',
        websiteContent: {},
      },
    };
  },
  mounted() {
    this.updateCommitData().then(() => {
      this.commitUpdated = true;
    });
  },
  methods: {
    async updateCommitData()
    {
      this.commitUpdated = false;
      this.commit.date = Date.now();
      this.commit.wallConfig = { ...this.wallConfig, live: {}, commits: {} };
      this.commit.checksum = this.checksum(JSON.stringify(this.commit.wallConfig));
    },
    checksum(data)
    {
      const options = {
        algorithm: 'sha1',
        encoding: 'hex',
      };
      const hash = crypto.createHash(options.algorithm);

      if (!hash.write)
        hash.update(data);// pre-streaming crypto API in node < v0.9
      else
        hash.write(data);
      return hash.digest(options.encoding);
    },
    async deploy()
    {
      this.commit.id = uuid.v4();
      const fontCSS = await this.$store.dispatch('getImportedFontCSS');
      this.$store.dispatch('deployWall',
        {
          ...this.commit,
          mainCss: `${fontCSS}${this.customCSS}`,
        }).then(() => {
        this.deploymentSuccessNotif({ message: `Le deploiement en production a bien été éffectué !<br><br><span class="text-muted">${this.commit.id}</span>` });
        this.commit = {
          id: '',
          date: 0,
          checksum: '',
          wallConfig: {},
          mainCss: '',
        };
        this.$emit('close');
      });
    },
  },
  computed: {
    ...mapGetters(['wallConfig', 'customCSS']),
    formatDate()
    {
      return this.$moment(Date.now()).format('Do MMMM YYYY, h:mm:ss a');
    },
  },
  notifications: {
    deploymentSuccessNotif: {
      type: VueNotifications.types.success,
      title: 'Déploiement terminé !',
      message: 'Le déploiement en production a bien été effectué !',
    },
  },
};

</script>

<style scoped>

</style>
