import { firebaseAction } from 'vuexfire';
import firebaseReference, { fbInit } from './FirebaseReference';
import { version } from '../../../package.json';
import firebaseBindings from './FirebaseBindings';

/**
 * @brief Store functions, mostly related to firebase
 * Most of the action will take a payload argument, it must be formmated like this :
 *  payload: {
 *   endpoint: '',    // the node name you want to bind
 *   objectName: '',  // Name of the object you want to bind, must be available in the state (optional in the action 'updateConfig')
 *   object: {},      // the bounded object (optional in the action 'bindConfig/Data')
 *  },
 */
const actions = {
  ...firebaseBindings,
  /**
   * Set the website content from the vue actual state
   * Only called by a subscriber located in App.vue when a mutation is called with payload.dbSync = true
   */
  setWallConfig: firebaseAction(async function callback({ state, getters, commit })
  {
    const fbRef = await firebaseReference.root;
    commit('toggleFirebaseSpinner', { value: true, dbSync: false });
    fbRef.update(state.wallConfigData).then(() => {
      console.log('Website modification send to database');
      commit('toggleFirebaseSpinner', { value: false, dbSync: false });
    });
  }),

  async deployWall({ dispatch }, payload)
  {
    console.log('Deploying to production', payload.id);
    dispatch('addCommitToHistory', payload).then(() => dispatch('setLiveWebsite', payload));
  },

  /**
   * Add a commit to the website history based on the payload
   * @param payload: {
   *   // no specific field except :
   *   date: the date when the commit is created
   * }
   */
  addCommitToHistory: firebaseAction(async function callback({ getters }, payload)
  {
    console.log('Add Commit to history', payload.date);
    const fbRef = await firebaseReference.root;
    return fbRef.child('config/commits').child(payload.date).set(payload);
  }),

  /**
   * Set the "live" (production) website from the payload information
   * @param wallConfig: the website final html content
   */
  setLiveWebsite: firebaseAction(async function callback({ getters }, { id, wallConfig, mainCss })
  {
    const fbRef = await firebaseReference.root;
    return fbRef.child('config/live').set({
      wallConfig: wallConfig || {},
      commitId: id || '',
      mainCss: mainCss || '',
    });
  }),

  /**
   * Return the Google font list form the Google Api
   * @returns an array containing the fonts, empty upon failure
   */
  async getGoogleFontList()
  {
    let out = [];

    try
    {
      const response = await fetch('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyARhQYV7SROMQ2fdaCaPpILJtliMajpEKU');
      if (!response.ok)
        return out;
      const content = await response.json();
      out = content.items;
    } catch (err)
    {
      console.error(err);
      out = [];
    }
    return out;
  },

  /**
   * Return the CSS for custom fonts
   * @return string containing the imports
   */
  getImportedFontCSS({ getters })
  {
    let fontsCSS = '';
    Object.values(getters.googleFonts).forEach((element) => {
      if (element.source === 'GoogleFont' && element.GoogleFontURL !== undefined && element.GoogleFontURL !== '')
        fontsCSS += `@import url(${element.GoogleFontURL});`;
      if (element.source === 'Custom' && element.fontFace !== undefined && element.fontFace !== '')
        fontsCSS += element.fontFace;
    });
    return fontsCSS;
  },

  /**
   * Upload a font file to the captag cdn and return the access url
   * @param getters
   * @param fileData the font file actual data
   * @param fileName the file name that will be used as public_id
   * @returns the file access URL
   */
  async uploadFont({ getters }, { fileData, fileName })
  {
    const formData = new FormData();
    formData.append('', fileData, fileName);
    formData.append('apiKey', 'SjX6yhkY4RSlpE32x4xK');
    formData.append('folder', `${getters.eventID}/website/fonts`);

    const response = await fetch('https://europe-west1-captag-events.cloudfunctions.net/uploads/uploadFile', {
      method: 'POST',
      body: formData,
    });
    if (!response.ok)
    {
      console.log(`Error while uploading font : ${response.message}`);
      return undefined;
    }
    const data = await response.json();
    if (!data || !data.url)
      return undefined;
    return data.url;
  },

  async getWebappModules({ getters })
  {
    const [data, err] = await firebaseReference.fetchAPI(`https://captag.events/ext_api/getWallsFromEventID/?eventID=${getters.eventID}`, { addTokenInQuery: true });
    if (!data || err)
    {
      console.error(`Error while fetching webapp walls: ${err}`);
      return {};
    }
    return data.details || {};
  },

  /**
   * Decode the TK parameter expected in the URL
   * once the token is decrypted the action loadTokenParams is dispatched
   * @param dispatch
   * @param commit
   * @param getters
   */
  async decodeUrlParams({ dispatch, commit, getters })
  {
    const searchParams = new URLSearchParams(window.location.search);
    const claims = await fbInit(searchParams.get('tk') || '');
    if (!claims)
    {
      commit('toggleLoadingError', { error: true, content: { code: 403, message: 'Veuillez vous rapprocher de votre contact chez Captag' } });
      return;
    }
    commit('setTokenId', { tokenID: searchParams.get('tk'), dbSync: false });
    commit('setVersionNumber', { version, dbSync: false });

    if (!claims.eventID || !claims.animID) {
      console.log('No path provided, stopping');
      commit('toggleLoadingError', { error: true, content: { code: 404, message: 'Missing parameter path' } });
      return;
    }
    commit('setPath', { path: `${claims.eventID}/${claims.animID}`, dbSync: false });

    dispatch('bindWallConfig').then(() => {
      dispatch('bindAnimations');
      dispatch('bindDatas');
      commit('toggleLoading', { value: false, dbSync: false });
    });
  },
};

export default actions;
