<template>
  <div class="h-100" v-on:scroll.stop>
    <ErrorPage v-if="isLoadingError" />
    <LoadingPage v-else-if="isLoading"/>
    <div class="vh-100 container-fluid d-flex flex-column">
      <TopBar class="flex-shrink-1"/>
      <div class="row mh-100 mt-2" style="height: 90%">
        <div class="col-md-6 mb-4 mb-sm-0 h-100">
          <card headerBackground="bg-primary" :titleSize="3" title="<i class='fas fa-folder-open mx-3'></i>Sources et moderation" class="h-100">
            <SourcesEditor/>
          </card>
        </div>
        <div class="col-md-6 mb-3 h-100">
          <card :titleSize="3" title="<i class='fas fa-cogs mx-3'></i>Configuration" class="h-100">
            <WallConfiguration/>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import LoadingPage from './components/LoadingPage.vue';
import ErrorPage from './components/ErrorPage.vue';
import TopBar from './components/TopBar.vue';
import Card from './components/Utilities/Card.vue';
import WallConfiguration from './components/WallConfiguration.vue';
import SourcesEditor from './components/Sources/SourcesEditor.vue';

export default {
  name: 'App',
  components: {
    SourcesEditor,
    WallConfiguration,
    Card,
    TopBar,
    ErrorPage,
    LoadingPage,
  },
  created()
  {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type.includes('vuexfire'))
        return;
      if (mutation.payload.dbSync === undefined)
      {
        console.warn(`Mutation ${mutation.type} called and does not specify database synchronisation`);
      }
      if (mutation.payload.dbSync === true) {
        this.$store.dispatch('setWallConfig');
      }
    });
  },
  mounted() {
    this.$store.dispatch('decodeUrlParams');
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'isLoadingError',
      'path',
    ]),
  },
};

</script>

<style scoped>
</style>
