<template>
  <div class="card d-flex">
    <div class="card-header flex-shrink-1" v-bind:class="headerBackground">
      <slot name="header">
        <div class="row d-inline-flex align-items-center w-100">
          <div class="col-auto">
            <slot name="header-left">
              <div v-if="titleContent !== ''" v-html="titleContent"></div>
            </slot>
          </div>
          <div class="col">
            <slot name="header-center"></slot>
          </div>
          <div class="col-auto">
            <slot name="header-right"></slot>
          </div>
        </div>
      </slot>
    </div>
    <!-- :style="(showFooter) ? 'height: calc(100% - 120px)' : 'height: calc(100% - 60px)'"-->
    <div class="card-body flex-grow-1" style="max-height: 92%">
      <perfect-scrollbar class="h-100 pe-3 ps-2" :options="{ suppressScrollX: true, wheelPropagation: false }">
        <slot></slot>
      </perfect-scrollbar>
    </div>
    <div v-show="showFooter" class="card-footer text-muted flex-shrink-1">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Card',
  props: {
    headerBackground: {
      type: String,
      required: false,
      default: 'bg-secondary',
    },
    titleSize: {
      type: Number,
      required: false,
      default: 4,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    titleColor: {
      type: String,
      required: false,
      default: 'white',
    },
    showFooter: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    titleContent()
    {
      if (this.title === undefined || this.title === '')
        return '';
      switch (this.titleSize)
      {
        case 1:
          return `<h1 class="m-0" style='color: ${this.titleColor}'>${this.title}</h1>`;
        case 2:
          return `<h2 class="m-0" style='color: ${this.titleColor}'>${this.title}</h2>`;
        case 3:
          return `<h3 class="m-0" style='color: ${this.titleColor}'>${this.title}</h3>`;
        case 4:
          return `<h4 class="m-0" style='color: ${this.titleColor}'>${this.title}</h4>`;
        case 5:
          return `<h5 class="m-0" style='color: ${this.titleColor}'>${this.title}</h5>`;
        case 6:
          return `<h6 class="m-0" style='color: ${this.titleColor}'>${this.title}</h6>`;
        default:
          return `<h4 class="m-0" style='color: ${this.titleColor}'>${this.title}</h4>`;
      }
    },
  },
};

</script>

<style scoped>

</style>
